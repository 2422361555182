const addIframe = () => {

    const gamesBtn = document.querySelectorAll('.games__button'),
          modal = document.querySelector('.modal'),
          wrapper = document.querySelector('.modal__wrapper'),
          close = document.querySelector('.modal__close');

    const gameLinks = {
        valleyofdreams: 'https://demo.evoplay.games/demo/fullstate/html5/evoplay/valleyofdreams',
        riseofhorus: 'https://demo.evoplay.games/demo/fullstate/html5/evoplay/riseofhorus',
        surfzone: 'http://demo.evoplay.games/demo/fullstate/html5/evoplay/surfzone',
        livingtales: 'http://demo.evoplay.games/demo/fullstate/html5/evoplay/livingtales'
    };

    const element = document.createElement('iframe');
    element.classList.add('modal__iframe');
          
    gamesBtn.forEach((item) => {
        item.addEventListener('click', (e) => {
            const game = e.target.getAttribute('data-game');
            element.src = gameLinks[game];
            element.allow = 'fullscreen; autoplay';
            
            modal.classList.add('modal_active');
            document.body.style.overflow = 'hidden';
            wrapper.append(element);
            
        });
    });

    close.addEventListener('click', () => {
        element.remove();
        modal.classList.remove('modal_active');
        document.body.style.overflow = 'auto';
    });

};

export default addIframe;