"use strict";

import hamburger from "./modules/hamburger.js";
import headerBg from "./modules/headerBg.js";
import showQuestion from "./modules/showQuestion.js";
import addIframe from "./modules/addIframe.js";



hamburger();
headerBg();

try {
    showQuestion();
} catch (error) {
    
}

try {
    addIframe();
} catch (error) {
    
}



