const headerBg = () => {
    const header = document.querySelector('.header'),
          headerHeight = header.style.height;
    

    document.addEventListener('scroll', () => {
        const windowScroll = window.scrollY || document.documentElement.scrollTop;
        if (window.innerWidth > 768 && windowScroll > headerHeight) {
            header.classList.add('header_scroll'); 
        } else {
            header.classList.remove('header_scroll'); 
        }
    });

};

export default headerBg;

