const showQuestion = () => {
    const item = document.querySelectorAll('.questions__item'),
          title = document.querySelectorAll('.questions__text-title'),
          text = document.querySelectorAll('.questions__text');

    item.forEach((element, i) => {
        element.addEventListener('click', (e) => {

            if (item[i].classList.contains('questions__item_active')) {
                text[i].style.maxHeight = 0;
                element.classList.remove('questions__item_active');
                title[i].classList.remove('questions__text-title_active');
                text[i].classList.remove('questions__text_active');
            } else {
                text[i].style.maxHeight = text[i].scrollHeight + 'px';
                element.classList.add('questions__item_active');
                title[i].classList.add('questions__text-title_active');
                text[i].classList.add('questions__text_active');
            }
        });
    });

};

export default showQuestion();